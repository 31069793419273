import axios from '@axios'

export const fetchTuitionCardTemplatesList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/tuition-card-templates', { params })
  return res
}

export const fetchTuitionCardTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/tuition-card-templates/${id}`)
  return res
}

export const createTuitionCardTemplate = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/tuition-card-templates', params)
  return res
}

export const updateTuitionCardTemplate = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/tuition-card-templates/${params.id}`, params)
  return res
}

export const deleteTuitionCardTemplate = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/tuition-card-templates/${id}`)
  return res
}

export const cloneTuitionCardTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`auth/tuition-card-templates/${id}/clone`)
  return res
}

export const fetchTuitionCardTemplateSettings = async (id: string): Promise<any> => {
  const res = await axios.get(`auth/programs-related-settings/${id}/tuition_card_template`)
  return res
}

export const setTuitionCardTemplateDefaultSettings = async (id: string): Promise<any> => {
  const res = await axios.get(`auth/tuition-card-templates/${id}/set-default`)
  return res
}

export const fetchTuitionCardPlaceholders = async (params?: any): Promise<any> => {
  const res = await axios.get('auth/tuition-card-templates/placeholders/list', { params })
  return res
}
