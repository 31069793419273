



























































































































































































































import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BButton, BLink, BPopover,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { filters } from '@core/mixins/filters'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { appTuitionCardList } from '@/store/modules/app-tuition-card-templates/app-tuition-card-list'
import { permissionSubjects } from '@/libs/acl/constants'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'TuitionCardTemplatesList',
  components: {
    BPopover,
    ConfirmationModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,

    vSelect,

    TableSpinner,
  },
  mixins: [filters],
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      confirmationModalToggle: false,
      permissionSubjects,
    }
  },
  computed: {
    tableColumns() {
      return appTuitionCardList.getters.getTableColumns
    },
    perPage() {
      return appTuitionCardList.getters.getPerPage
    },
    totalItems() {
      return appTuitionCardList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appTuitionCardList.getters.getCurrentPage
      },
      set(val: number) {
        appTuitionCardList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appTuitionCardList.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appTuitionCardList.getters.getSearchQuery
      },
      set(val: string) {
        appTuitionCardList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy: {
      get() {
        return appTuitionCardList.getters.getSortBy
      },
      set(val: string) {
        appTuitionCardList.mutations.SET_SORT_BY(val)
      },
    },
    isSortDirDesc: {
      get() {
        return appTuitionCardList.getters.getIsSortDirDesc
      },
      set(val: boolean) {
        appTuitionCardList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    dataMeta() {
      return appTuitionCardList.getters.getDataMeta
    },
    settings() {
      return appTuitionCardList.getters.getTuitionCardBaseTemplateSetting
    },
    deletableTemplateId: {
      get() {
        return appTuitionCardList.getters.getDeletableTemplateId
      },
      set(id: string) {
        appTuitionCardList.mutations.SET_DELETABLE_TEMPLATE_ID(id)
      },
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val: number) {
      appTuitionCardList.mutations.SET_PER_PAGE(val)
    },
    async fetchTuitionCardTemplatesList() {
      return appTuitionCardList.actions.fetchTuitionCardTemplatesList()
    },
    openDeleteConfirmation(id: string) {
      this.deletableTemplateId = id
      this.$bvModal.show('confirmation-delete-template')
    },
    async deleteItem() {
      await appTuitionCardList.actions.deleteTuitionCardTemplate(this.deletableTemplateId)
      this.$bvModal.hide('confirmation-delete-template')
      this.updateTable()
    },
    async cloneTemplate(id: string) {
      await appTuitionCardList.actions.cloneTuitionCardTemplate(id)
      this.updateTable()
    },
    setFilterParams() {
      appTuitionCardList.mutations.SET_FILTER_PARAMS()
    },
    updateTable() {
      (this.$refs.refTuitionCardTemplatesListTable as BTable).refresh()
    },
    async updateDefaultForInstance(id: string) {
      await appTuitionCardList.actions.updateTuitionCardBaseTemplateSetting(id)
      this.updateTable()
    },
  },
})
