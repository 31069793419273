import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { TuitionCardListState, ITuitionCardTemplatesListRequest } from './types'
import {
  cloneTuitionCardTemplate,
  deleteTuitionCardTemplate,
  fetchTuitionCardTemplatesList,
  setTuitionCardTemplateDefaultSettings,
} from '@/api/tuitionCardTemplates'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// eslint-disable-next-line import/prefer-default-export
export const appTuitionCardList = createModule(
  'appTuitionCardList',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'created_by', label: 'created by', sortable: true },
        { key: 'created_at', label: 'created at', sortable: true },
        { key: 'is_default', label: 'default for instance', sortable: true },
        { key: 'actions', class: 'table-actions' },
      ],
      perPage: Number(localStorage.getItem('tuitionCardTemplatesPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: 'created_at',
      isSortDirDesc: true,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      deletableTemplateId: null,
      tuitionCardBaseTemplateSetting: {
        id: null,
        program_id: null,
        entity_id: null,
        entity_type: null,
      },
    } as TuitionCardListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getDataMeta: state => state.dataMeta,
      getDeletableTemplateId: state => state.deletableTemplateId,
      getTuitionCardBaseTemplateSetting: state => state.tuitionCardBaseTemplateSetting,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('tuitionCardTemplatesPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('tuitionCardTemplatesPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
      },
      SET_DELETABLE_TEMPLATE_ID: (state, val) => {
        state.deletableTemplateId = val
      },
      SET_TUITION_CARD_BASE_TEMPLATE_SETTINGS(state, val) {
        state.tuitionCardBaseTemplateSetting = val
      },
    },
    actions: {
      async fetchTuitionCardTemplatesList({ state }) {
        try {
          const queryParams: ITuitionCardTemplatesListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
          }
          if (state.searchQuery) queryParams.name = state.searchQuery

          const response:any = await fetchTuitionCardTemplatesList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appTuitionCardList.mutations.SET_TOTAL_ITEMS(total)
          appTuitionCardList.mutations.SET_DATA_META(dataMeta)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching letters' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async deleteTuitionCardTemplate(ctx, id) {
        try {
          await deleteTuitionCardTemplate(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success deleting Tuition Card Template',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting Tuition Card Template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async cloneTuitionCardTemplate(ctx, id: string) {
        try {
          await cloneTuitionCardTemplate(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success cloning template',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error cloning template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async updateTuitionCardBaseTemplateSetting(ctx, id: string) {
        try {
          await setTuitionCardTemplateDefaultSettings(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tuition Card default template was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error on updating',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appTuitionCardList.register(store)
